
import React from 'react'
import Navbar from '../inc/Navbar'
import Slider from '../inc/Slider'
import Sliderpage from '../inc/Sliderpage'

import "./Home.css";
import Events from "../images/events.jpg";
import { DefaultPlayer as Video } from 'react-html5video';
import 'react-html5video/dist/styles.css';
import party from "../images/hotelsangamevent.mp4"
import eventv from "../images/soundvideo.mp4";
import Fronvideo from "../images/frontvideo.mp4";
import Hotelfront from "../images/maingate.jpg";
import Restaurantfront from "../images/restrofront.jpg";
import Restaurantinside from "../images/restroinside.jpg";
import Bar from "../images/bar.jpg";
import Conferenceinside from "../images/coninside.jpg";
import Barinside from "../images/barinside.jpg";

import Partybook from "../images/partybook.mp4"
import Restuarentdsangam from "../images/restroluxury.jpg"
import Room from "../images/room.jpg"
import Rooms from "../images/rooms.jpg"
import { Link } from "react-router-dom";
import Reception from "../images/RECEPTION.jpg";
import Conference1 from "../images/conference.jpg";
import Eventground from "../images/EVENTGROUND.jpg";
import Conference from "../pages/Conference";
import Roominside from "../images/roomsss.jpg"



import ScrollToTop from "react-scroll-to-top";
import Sameevent from './Sameevent';
import Rest from './Rest';
function Home() {
  return (
    <div>
      <Navbar />
      <br />
      <br />
      <br />
      <br />

      <Slider />
      <br />
      <center>
        <h1 style={{ color: "teal" }}>Hotel Sangam</h1>
        <h2>Best Hotel in Berhampore</h2>
      </center>
      <ScrollToTop smooth top="100" color="Darkblue" />
      <div className="album py-3">

        <div className="container">

          <div className="row ">
            <div className="col-md-4">
              <div className="alert alert-info" role="alert">
                <h5 className='mb-3'> 100% Safe Stay</h5>
                <p className='mb-2'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-circle" viewBox="0 0 16 16">
                  <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0" />
                  <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
                </svg> Mandatory temp check for all</p>
                <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-circle" viewBox="0 0 16 16">
                  <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0" />
                  <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
                </svg> Social distancing at reception/lifts</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="alert alert-danger" role="alert">
                <h5 className='mb-3'> 100% Sanitized</h5>
                <p className='mb-2'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-circle" viewBox="0 0 16 16">
                  <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0" />
                  <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
                </svg> Mandatory temp check for all</p>
                <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-circle" viewBox="0 0 16 16">
                  <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0" />
                  <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
                </svg> Hospital grade chemicals for cleaning</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="alert alert-primary" role="alert">
                <h5 className='mb-3'> 100% Convenient</h5>
                <p className='mb-2'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-circle" viewBox="0 0 16 16">
                  <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0" />
                  <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
                </svg> Contact-less Check in</p>
                <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-circle" viewBox="0 0 16 16">
                  <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0" />
                  <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
                </svg> Huge Parking Facility  </p>
              </div>
            </div>
          </div>
        </div>
      </div>

<center>
<a href='menu'>
  <button className="menu"> Click Menu </button>
</a>
</center>
      <br />
      <div className="album py-3">

        <div className="container">

          <div className="row ">
            <div className="col-md-4 mt-2">
              <div className="card">
                <img src={Hotelfront} className="card-img-top" alt="Hotelfront" />
                <div className="card-body">
                  <h3 className="card-title">Hotel Sangam</h3>
                  <p className="card-text">Hotel Sangam Berhampore</p>

                </div>
              </div>
            </div>
            <div className="col-md-4 mt-2">
              <div className="card" >
                <img src={Reception} className="card-img-top" alt="Reception" />
                <div className="card-body">
                  <h3 className="card-title">Reception</h3>
                  <p className="card-text">Hotel Sangam Reception </p>

                </div>
              </div>
            </div>
            <div className="col-md-4 mt-2">
              <div className="card" >
                <Link to="/room">
                  <img src={Roominside} className="card-img-top" alt="Room" />
                </Link>
                <div className="card-body">
                  <h3 className="card-title">Room</h3>
                  <p className="card-text">Hotel Sangam Room</p>

                </div>
              </div>
            </div>

            <div className="col-md-4 mt-2">
              <div className="card" >
                <Link to="/restaurant">
                  <img src={Restaurantfront} className="card-img-top" alt="Restaurantfront" />
                </Link>
                <div className="card-body">
                  <h3 className="card-title">Restaurant Front View</h3>
                  <p className="card-text">Hotel Sangam Restaurant</p>

                </div>
              </div>
            </div>
            <div className="col-md-4 mt-2">
              <div className="card" >
                <Link to="/restaurant">
                  <img src={Restaurantinside} className="card-img-top" alt="Restaurantinside" />
                </Link>
                <div className="card-body">
                  <h3 className="card-title">Restaurant Inside</h3>
                  <p className="card-text">Hotel Sangam</p>

                </div>
              </div>
            </div>
            <div className="col-md-4 mt-2">
              <div className="card" >
                <img src={Bar} className="card-img-top" alt="Bar" />
                <div className="card-body">
                  <h3 className="card-title">Bar</h3>
                  <p className="card-text">Hotel Sangam</p>

                </div>
              </div>
            </div>
            <div className="col-md-4 mt-2">
              <div className="card" >
                <img src={Barinside} className="card-img-top" alt="Bar" />
                <div className="card-body">
                  <h3 className="card-title">Bar Inside</h3>
                  <p className="card-text">Hotel Sangam</p>

                </div>
              </div>
            </div>

            <div className="col-md-4 mt-2">
              <div className="card" >
                <Link to="/event">
                  <img src={Conference1} className="card-img-top" alt="Conference" />
                </Link>
                <div className="card-body">
                  <h3 className="card-title">Conference</h3>
                  <p className="card-text">Any Corporate Meeting, Seminar, Party ..</p>

                </div>
              </div>
            </div>
            <div className="col-md-4 mt-2">
              <div className="card" >
                <img src={Conferenceinside} className="card-img-top" alt="Conferenceinside" />
                <div className="card-body">
                  <h3 className="card-title">Conference Inside</h3>
                  <p className="card-text">Hotel Sangam</p>

                </div>
              </div>
            </div>
            <div className="col-md-4 mt-2">
              <div className="card" >
                <Link to="/event">
                  <img src={Eventground} className="card-img-top" alt="Eventground" />
                  <div className="card-body">
                    <h3 className="card-title">Event Ground</h3>
                    <p className="card-text">Birth Day, Wedding, Rice Ceremony Event..</p>

                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="album py-3">
        <div className="container">
          <div className="row ">
            <div className="col-md-6 mt-2">

              <Video Play loop onCanPlayThrough={() => {
                console.log('video play')
              }}>
                <source src={eventv} type="video/webm" />
              </Video>
            </div>
            <div className="col-md-6 mt-2">

              <Video Play muted loop onCanPlayThrough={() => {
                console.log('video play')
              }}>
                <source src={Fronvideo} type="video/webm" />
              </Video>
            </div>

          </div>
        </div>
      </div>





      <br />
      <div className="album py-0">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mt-3">
              <Video Play muted loop onCanPlayThrough={() => {
                console.log('video play')
              }}>
                <source src={party} height="250px" type="video/webm" />
              </Video>
            </div>
            <div className="col-md-6 mt-3">

              <Video Play muted loop onCanPlayThrough={() => {
                console.log('video play')
              }}>
                <source src={Partybook} height="250px" type="video/webm" />
              </Video>
            </div>
          </div>
        </div>
      </div>

      
      <div className="album py-3">

        <div className="container">

          <div className="row ">
            <div className="col-md-8">
              <h1 className='texts mb-3'>Welcome to Hotel Sangam</h1>
              <h4 className='mb-2'>Berhampore, Mursidabad</h4>
              <p className='texts'> Conveniently Located, Hotel Sangam is the oldest luxury hotel situated in Berhampore, Murshidabad District (W.B). It is a smart choice for travellers looking for a comfortable and hassle free stay. The hotel
                is a short 5 minutes drive from Berhampore railway station & the hotel location provides an ideal base for guest who need seamless access to key areas in the city. Well connected by railway & road from Kolkata, Malda & Siliguri.
                The 3 star property has air conditioned rooms with free wi-fi, conference room, bar, family restaurant & banquet hall. Exerience an upscale hospitality in this property which is a favourite among frequent travellers of the city, families, business guests & tourists.</p>

            </div>
            <div className="col-md-4">
              <Sliderpage />

            </div>
          </div>
        </div>
      </div>
      <br />
      <Sameevent />
      <br />
      <br />
      <Rest />
      <br />
      <br />
      <Conference />
      <br />
      <br />
      <div className="hetelsangam">
        <center>
          <div className="sentancestyle">
            <h1><b>HOTEL SANGAM - UNIQUE EXPERIENCE </b></h1><br />
          </div>
          <div className="sentancestyles">
            <h3>Best Hotel in Berhampore</h3>
          </div>
        </center>
      </div>

      <br />
      <div className="album py-3">

        <div className="container">
          <div class="alert alert-success" role="alert">
            <center> How Can We Help You?  <a href='tel: 9434237099' className="buttoncall button1"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-inbound" viewBox="0 0 16 16">
              <path d="M15.854.146a.5.5 0 0 1 0 .708L11.707 5H14.5a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 1 0v2.793L15.146.146a.5.5 0 0 1 .708 0zm-12.2 1.182a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
            </svg> Speak to Room Book</a> </center>
          </div>
        </div>
      </div>



      <div className="album py-3">

        <div className="container">

          <div className="row ">
            <div className="col-md-6 mt-3">

              <div class="containerdeta">
                <Link to="/restaurant" target='_blank'>
                  <img src={Restuarentdsangam} alt="restroluxury" className="image" />
                </Link>
                <div class="middle">
                  <Link to="/restaurant" target='_blank'>
                    <div class="text">Learn More <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
                    </svg></div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6 mt-3">

              <div class="containerdeta">
                <Link to="/event" target='_blank'>
                  <img src={Events} alt="restro" className="image" />
                </Link>

                <div class="middle">
                  <Link to="/event" target='_blank'>
                    <div className="text">Learn More <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
                    </svg></div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6 mt-3">

              <div class="containerdeta">
                <Link to="/room" target='_blank'>
                  <img src={Room} alt="room-hoelsangam" className="image" />
                </Link>
                <div class="middle">
                  <Link to="/room" target='_blank'>
                    <div className="text">Learn More <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
                    </svg></div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6 mt-3">

              <div class="containerdeta">
                <Link to="/room" target='_blank'>
                  <img src={Rooms} alt="Rooms" className="image" />
                </Link>
                <div class="middle">
                  <Link to="/room" target='_blank'>
                    <div className="text">Learn More <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
                    </svg></div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <br />
    </div >
  )
}

export default Home