import React from 'react'
import Navbar from '../inc/Navbar'
import Menupage from './Menupage'

function Menu() {
  return (
    <div>
        <Navbar/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        
        <Menupage/>
    </div>
  )
}

export default Menu