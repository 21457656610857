import React, { useState, useEffect }  from 'react';

import { useNavigate} from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
import {db} from '../../firebase'

function Standradroom () {
   const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
      const [showPerPage, setShowPerPage] = useState(9);
      const [pagination, setPagination] =useState({
          start: 0,
          end: showPerPage,
      });
  
      const onPaginationChange = (start,  end) =>{
          setPagination({start: start, end: end});
      };
      const [searchTerm, setsearchTerm] = useState("");
      const [standradroomslide, setStandradroomslide] = useState([])
      const navigate = useNavigate()
  
      const standradroomslideCollectionRef = collection(db, "Standradroomslidersangam");
      useEffect(() => {
  
          const getStandradroomslide = async () => {
              const data = await getDocs(standradroomslideCollectionRef);
              setStandradroomslide(data.docs.map((doc) => ({...doc.data(), id: doc.id })))
          }
  
          getStandradroomslide()
      }, [])
  return (
    <div>

{standradroomslide.filter((val)=>{
      if (setsearchTerm === ""){
        return val;
      } {
        return val;
      }
    }).slice(pagination.start, pagination.end).map((besthotelberhampore) => { return (
       
       
                       
                            <div className="card-body">
                                   
                                   <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
  <div className="carousel-inner">
    <div className="carousel-item active">
      <img src={besthotelberhampore.slider1} className="d-block w-100" alt="..."/>
    </div>
    <div className="carousel-item">
      <img src={besthotelberhampore.slider2} className="d-block w-100" alt="..."/>
    </div>
    <div className="carousel-item">
      <img src={besthotelberhampore.slider3} className="d-block w-100" alt="..."/>
    </div>
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>
  
                                
                               
                              
                                {/* <h5 style={{color: "Darkblue"}}className="card-text"> ₹{website.saleprice} </h5>
                                <p style={{color: "Gray"}}className="card-text mb-2"> M.R.P.: <del> ₹{website.delete}</del> </p> */}
                               

                               
                    
                              
                            </div>
                           
                                )})} 
                            
                            {/* <PaginationOld showPerPage={showPerPage} 
                onPaginationChange={onPaginationChange}
                total={standradroomslide.length}
                /> */}
      
    </div>
  )
}

export default Standradroom

 
