import React from 'react'
import Navbar from "../inc/Navbar";
import ScrollToTop from "react-scroll-to-top";
import ReactWhatsapp from "react-whatsapp";

import Marriage from "../images/event-Marriage.jpg";
import Anyevent from "../images/event2.jpg";
import Sameevent from './Sameevent';
import Conference from './Conference';
import Frontview from "../images/frontview.jpg";


function Event() {
    return (
        <div>
            <Navbar />
            <br />
            <br />

            <ScrollToTop smooth top="100" color="maroon" />
            <div style={{ color: "Green" }} class="fixed-bottom "><a class="buttoncontact" href='tel: +91 9434237099'>Marriage Book  <div className="spinner-grow text-light spinner-grow-sm" role="status">
                <span className="visually-hidden"> Loading...</span>
            </div></a></div>

            {/* <div className="album py-2">

<div className="container">
            <Video Play loop onCanPlayThrough={() => {
                console.log('video play')
              }}>
                <source    src={eventvideo} type="video/webm" />
              </Video>
              </div>
              </div> */}
            <br />
            <br/>
            <br/>
            <br />
            <div className="album py-3">

                <div className="container">

                    <div className="row ">
                        <div className="col-md-6">
                            <h1 className='texts mb-2'>Welcome to Hotel Sangam</h1>
                            <h2 style={{color:"maroon"}}><b>MARRIAGE</b></h2>
                            <h4 className='mb-2'>Berhampore, Mursidabad</h4>
                            
                            <p> Popular Marriage Book</p>
                            <a href="tel: +91 9434237099" className="buttonevent button1 mb-2">    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-forward" viewBox="0 0 16 16">
                                <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zm10.762.135a.5.5 0 0 1 .708 0l2.5 2.5a.5.5 0 0 1 0 .708l-2.5 2.5a.5.5 0 0 1-.708-.708L14.293 4H9.5a.5.5 0 0 1 0-1h4.793l-1.647-1.646a.5.5 0 0 1 0-.708z" />
                            </svg> BOOK NOW</a>
                            <br />
                            <ReactWhatsapp number="+91 9434237099" className="btn btn-success mb-4" message="I am interested Hotel Sangam"> WhatsApp Book</ReactWhatsapp>

                        </div>
                        <div className="col-md-6">
                            <img src={Marriage} class="card-img-top" alt="event-Marriage" />
                           

                        </div>
                    </div>
                </div>
            </div>
            <br />

            <div className="album py-3">

                <div className="container">

                    <div className="row ">
                        <div className="col-md-6">
                        <img src={Anyevent} class="card-img-top" alt="event" />
                           
                        </div>
                        <div className="col-md-6">
                    
                        <h1 className='texts mb-2'>Welcome to Hotel Sangam</h1>
                        <h2 style={{color:"maroon"}}><b>EVENT</b></h2>
                            <h4 className='mb-2'>Berhampore, Mursidabad</h4>
                     
                            <p> Any Event Book</p>

                            <a href="tel: +91 9434237099" className="buttonevent button1 mb-2">    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-forward" viewBox="0 0 16 16">
                                <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zm10.762.135a.5.5 0 0 1 .708 0l2.5 2.5a.5.5 0 0 1 0 .708l-2.5 2.5a.5.5 0 0 1-.708-.708L14.293 4H9.5a.5.5 0 0 1 0-1h4.793l-1.647-1.646a.5.5 0 0 1 0-.708z" />
                            </svg> BOOK NOW</a>
                            <br />
                            <ReactWhatsapp number="+91 9434237099" className="btn btn-success" message="I am interested Hotel Sangam"> WhatsApp Book</ReactWhatsapp>


                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div className="album py-3">

<div className="container">

    <div className="row ">
        <div className="col-md-6">
            <h1 className='texts mb-2'>Welcome to Hotel Sangam</h1>
            <h2 style={{color:"maroon"}}><b>MARRIAGE</b></h2>
            <h4 className='mb-2'>Berhampore, Mursidabad</h4>
            
            <p> Popular Marriage Book</p>
            <a href="tel: +91 9434237099" className="buttonevent button1 mb-2">    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-forward" viewBox="0 0 16 16">
                <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zm10.762.135a.5.5 0 0 1 .708 0l2.5 2.5a.5.5 0 0 1 0 .708l-2.5 2.5a.5.5 0 0 1-.708-.708L14.293 4H9.5a.5.5 0 0 1 0-1h4.793l-1.647-1.646a.5.5 0 0 1 0-.708z" />
            </svg> BOOK NOW</a>
            <br />
            <ReactWhatsapp number="+91 9434237099" className="btn btn-success mb-4" message="I am interested Hotel Sangam"> WhatsApp Book</ReactWhatsapp>

        </div>
        <div className="col-md-6">
            <img src={Frontview} class="card-img-top" alt="event-Frontview" />
           

        </div>
    </div>
</div>
</div>
<br />

            <Sameevent/>
            <br/>
            <Conference/>
    
        </div>

    )
}

export default Event