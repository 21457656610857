import React from 'react'
import welcomeslide from "../images/slidetree.jpg"
import welcomeslide2 from "../images/marriagegate.jpg"
import welcomeslide3 from "../images/restaurantimages.jpg"
import "./Sliderpage.css";

function Sliderpage() {
  return (
    <div>
        <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
  <div className="carousel-inner">
    <div className="carousel-item active">
        <div className='radiuspage'>
      <img src={welcomeslide3} className="d-block w-100" alt="hotelsangam"/>
    </div>
    </div>
    <div className="carousel-item">
      <img src={welcomeslide2} className="d-block w-100" alt="..."/>
    </div>
    <div className="carousel-item">
      <img src={welcomeslide} className="d-block w-100" alt="..."/>
    </div>
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>
    </div>
  )
}

export default Sliderpage