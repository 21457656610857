import React from 'react'

function Map() {
  return (
    <div>
  <div className="album py-1">

<div className="container">

  <div className="row ">
 
<iframe className='iframe-fluid' height="350" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=Sangam%20Hotel,%2037J8+7VQ,%20National%20Highway%2034%20Raninagar,%20Bhakuri%20Village,%20Talbagan,%20Berhampore,%20West%20Bengal%20742101%20+(Sangam%20Hotel,%2037J8+7VQ,%20National%20Highway%2034%20Raninagar,%20Bhakuri%20Village,%20Talbagan,%20Berhampore,%20West%20Bengal%20742101)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>

</div>
</div>
</div>

    </div>
  )
}

export default Map