import React from 'react'
import Room from "../images/1welcome.jpg";
import Main from "../images/main.jpg";
import Receptionmain from "../images/receptionimage.jpg";
import Event from "../images/EVENT.jpg";
import Homepage from "../images/HOME_BANNER.jpg";
import {Link} from "react-router-dom";

function Slider() {
  return (
    <div>

<div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
  <div className="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" aria-label="Slide 5"></button>





  </div>
  <div className="carousel-inner">
    <div className="carousel-item active">
  
      <img src={Main} className="d-block w-100" alt="hotelsangam-building"/>
     
    </div>
    <div className="carousel-item">
     
      <img src={Receptionmain} className="d-block w-100" alt="hotelsangam"/>
      
    </div>
    <div className="carousel-item">
      <Link to="/room">
      <img src={Room} className="d-block w-100" alt="hotelsangam"/>
      </Link>
    </div>
    <div className="carousel-item">
      <Link to="/event">
      <img src={Event} className="d-block w-100" alt="hotelsangam"/>
      </Link>
    </div>
  
    <div className="carousel-item">
      <img src={Homepage} className="d-block w-100" alt="hotelsangam"/>
    </div>
  
  
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>
    </div>
  )
}

export default Slider