


import React from 'react'

import Navbar from "../inc/Navbar";
import Sliderrestro from "../inc/Sliderrestro";
// import Menu from "../pages/Menu";
// import Cardmenu from "../images/psmenucard.jpg";
import ScrollToTop from "react-scroll-to-top";
import "./Restaurant.css";
import Menupage from './Menupage';
import RestroInterior from "../images/restro_interior.jpg";
import Restaurantimage from "../images/restaurantimage.jpg";

function Restaurant() {
  return (
    <div>

      <Navbar />
      <br />
      <br />
      <br />
      <br />
      <ScrollToTop smooth top="100" color="maroon" />
      <Sliderrestro />

      <br />
      <div className="album py-0">
        <div className="container">
          <div className="row">


            <br />


            <center>
              <div className="card-body">
                <h3 style={{ color: "Darkblue" }} className="card-text mb-3"> <b>HOTEL SANGAM - RESTAURANT & BAR </b></h3>
                < h5 style={{ color: "Darkblue" }} className="card-text mb-4">  Restaurant </h5>

                <div className="d-flex justify-content-between align-items-center mb-2">



                </div>
              </div>
            </center>
          </div>
        </div>



      </div>


      <div className="album py-3">

        <div className="container">

          <div className="row ">
            <div className="col-md-6">
              <div className="card" >
                <img src={RestroInterior} className="card-img-top" alt="RestroInterior" />
                <div className="card-body">
                  <h3 className="card-title">Our Restaurant</h3>
                

                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card" >
                <img src={Restaurantimage} className="card-img-top" alt="Restaurantimage"/>
                <div className="card-body">
                  <h3 className="card-title">Our Restaurant</h3>
              

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <center>
        <br />
      

      </center>

      <Menupage />
    </div>


  )
}

export default Restaurant