
import './App.css';
import {BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Home from "./components/pages/Home";
import Gallery from "./components/pages/Gallery";
import Footer from "./components/inc/Footer";
import Contactus from './components/pages/Contactus';
import Room from './components/pages/Room';
import Reserve from './components/pages/Reserve';
import About from './components/pages/About';
import Event from './components/pages/Event';
import Restaurant from './components/pages/Restaurant';
import Standard from './components/pages/Kingbed';
import Dulux from './components/pages/Dulux';
import Nonac from './components/pages/Nonac';
import Superd from './components/pages/Superd';
import Menu from './components/pages/Menu';
import Diamondsuites from './components/pages/Diamondsuites';
import Silversuite from './components/pages/Bronzesuite';
import Kingbed from './components/pages/Kingbed';
import Bronzesuite from './components/pages/Bronzesuite';
import Platunum from './components/pages/Platinum';
import Goldsuite from './components/pages/Goldsuite';


function App() {
  return (
    <Router>
        
    <div>
  
       
      <Routes>

     
      <Route axact path="/" element={<Home/>}/>
      <Route axact path="/contactus" element={<Contactus/>}/>
      <Route axact path="/gallery" element={<Gallery/>}/>
      <Route axact path="/room" element={<Room/>}/>
      <Route axact path="/bookingroom" element={<Reserve/>}/>
      <Route axact path="/about" element={<About/>}/>
      <Route axact path="/event" element={<Event/>}/>
      <Route axact path="/restaurant" element={<Restaurant/>}/>
      <Route axact path="/standard-room" element={<Standard/>}/>
      <Route axact path="/Deluxe-room" element={<Dulux/>}/>
      <Route axact path="/Nonac-room" element={<Nonac/>}/>
      <Route axact path="/super-deluxe-room-berhampore" element={<Superd/>}/>
      <Route axact path="/Menu" element={<Menu/>}/>
      <Route axact path="/Diamondsuite" element={<Diamondsuites/>}/>
      <Route axact path="/silversuite-room" element={<Silversuite/>}/>

       <Route axact path="/Kingbed" element={<Kingbed/>}/>
       <Route axact path="/Bronzesuite" element={<Bronzesuite/>}/>
       <Route axact path="/Platunum_room" element={<Platunum/>}/>
       <Route axact path="/Goldsuite" element={<Goldsuite/>}/>
      

      
      
     

      </Routes>
    <Footer/>
  
    </div>
   
    </Router>
  );
}

export default App;
