import React from 'react'
import Navbar from '../inc/Navbar'
import AddRoombook from '../AddRoombook'
import Roombooking from "../images/roombookform.jpg";

function Reserve() {
  return (
    <div>
        <Navbar/>
        <br/>
        <br/>
        <br/>
        <br/>

      
        <img src={Roombooking} className="d-block w-100" alt="hotelsangam"/>
        <br/>
        <br/>
        <br/>
        <AddRoombook/>
        <br/>
    </div>
  )
}

export default Reserve