
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage'
import "firebase/storage";



const firebaseConfig = {
  apiKey: "AIzaSyCR2D1E1YB6ncFIKp-3KSI1fxVHdbbHb9k",
  authDomain: "hotel-sangam.firebaseapp.com",
  projectId: "hotel-sangam",
  storageBucket: "hotel-sangam.appspot.com",
  messagingSenderId: "713182674666",
  appId: "1:713182674666:web:9eb165790377e20985e193",
  measurementId: "G-FZ37KEJNF2"
 
};
//init firebase app
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const auth = getAuth(app);
export const db = getFirestore(app);




export default app;


