import React, { useState, useEffect } from 'react';

import { useNavigate, Link} from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
import { db } from '../../firebase'
import Navbar from '../inc/Navbar';
import ScrollToTop from "react-scroll-to-top";


function Gallerypage(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showPerPage, setShowPerPage] = useState(40);
  const [pagination, setPagination] = useState({
    start: 0,
    end: showPerPage,
  });

  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };
  const [searchTerm, setsearchTerm] = useState("");
  const [gallerypage, setGallerypage] = useState([])
  const navigate = useNavigate()

  const gallerypageCollectionRef = collection(db, "Gallery");
  useEffect(() => {

    const getGallerypage = async () => {
      const data = await getDocs(gallerypageCollectionRef);
      setGallerypage(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
    }

    getGallerypage()
  }, [])
  return (

    <>
      <Navbar />

      <br />
      <br />
      <br />
      <br />
   
      <ScrollToTop smooth top="100" color="Darkblue" />


      <div className="hotelsangam">
    <br />
    <br />

    <div className="album py-3">

        <div className="container">


            <h3 className='mb-1'>GALLERY</h3>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li style={{ color: "white" }} className="breadcrumb-item"><Link to="/" style={{ color: "white" }} href="#"> Home</Link></li>
                    <li style={{ color: "white" }} className="breadcrumb-item active" aria-current="page">Gallery</li>
                </ol>
            </nav>
        </div>
    </div>

</div>
<br/>
<br/>

      <div className="album py-1">

        <div className="container">

          <div className="row">

            <div className="col-md-6">

              <div className="card-body">
                <input type="text" class="form-control" placeholder="Search" name="location"
                  onChange={(e) => {
                    setsearchTerm(e.target.value);
                  }}
                />



              </div>


            </div>

          </div>

        </div>
      </div>

      <div className="album py-0">

        <div className="container">

          <div className="row">
            {gallerypage.filter((val) => {
              if (setsearchTerm === "") {
                return val;
              } else if (
                val.title.toLowerCase().includes(searchTerm.toLowerCase())





              ) {
                return val;
              }
            }).slice(pagination.start, pagination.end).map((hotelsangam) => {
              return (

                <div className="col-md-6 mt-4" >
                
                    <div className="containerdeta">
                      <img src={hotelsangam.img} alt="hotelsangam-place" className="image mb-3" />
                      <div className="middle">
                        <div className="text">HOTEL SANGAM <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
  <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"/>
</svg> </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <center>
                        <h6 style={{ color: "Darkblue" }} className="card-text mb-2"> <b>{hotelsangam.title} </b></h6>
                      </center>
                      <center>
                        <p className="card-text mb-2">{hotelsangam.des}</p>
                      </center>



                      <div className="d-flex justify-content-between align-items-center">

                        {/* <button type="button" className="btn btn btn-dark">
                                        {website1.workf} 
                                        </button>  */}




                      </div>
                    </div>
                  </div>

                
              )
            })}
          </div>

        </div>
      </div>

<br/>
<br/>
    </>

  );
}

export default Gallerypage;