import React from 'react'
import  Navbar  from "../inc/Navbar";
import ScrollToTop from "react-scroll-to-top";
import {Link } from "react-router-dom";

function About() {
  return (
    <div>
        <Navbar/>
        <br/>
        <br/>
        <br/>
        <br/>
        <ScrollToTop smooth top="100" color="maroon" />

<div className="hotelsangam">
    <br />
    <br />

    <div className="album py-3">

        <div className="container">


            <h3 className='mb-1'>ABOUT US</h3>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li style={{ color: "white" }} className="breadcrumb-item"><Link to="/" style={{ color: "white" }} href="#"> Home</Link></li>
                    <li style={{ color: "white" }} className="breadcrumb-item active" aria-current="page">About Us</li>
                </ol>
            </nav>
        </div>
    </div>

</div>
<br/>
<br/>
<center>
      <h1 style={{color:"teal"}}>Hotel Sangam</h1>

      </center>

<br/>
<center><h1><b>About Us</b></h1></center>
<br/>

<div className="album py-2">

<div className="container">
    <h5 className='texts'> Conveniently Located, Hotel Sangam is the oldest luxury hotel situated in Berhampore, Murshidabad District (W.B). It is a smart choice for travellers looking for a comfortable and hassle free stay. The hotel
        is a short 5 minutes drive from Berhampore railway station & the hotel location provides an ideal base for guest who need seamless access to key areas in the city. Well connected by railway & road from Kolkata, Malda & Siliguri.
        The 3 star property has air conditioned rooms with free wi-fi, conference room, bar, family restaurant & banquet hall. Exerience an upscale hospitality in this property which is a favourite among frequent travellers of the city, families, business guests & tourists.
    </h5>

    </div>
</div>
<br/>
    </div>
  )
}

export default About