import React, { useState, useEffect } from 'react';
import { useNavigate, Link} from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
import ReactWhatsapp from "react-whatsapp";
import "./Room.css";

import {db} from '../../firebase'
import Navbar from '../inc/Navbar';
import ScrollToTop from "react-scroll-to-top";
import { Typewriter } from 'react-simple-typewriter' 
import { 
  FacebookShareButton, WhatsappShareButton, WhatsappIcon,
   FacebookIcon, TwitterShareButton,   EmailShareButton,   EmailIcon,

   LinkedinShareButton,
   LinkedinIcon,
   TwitterIcon,
}  from "react-share";



function Room() {
    const [ setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
      const [showPerPage, setShowPerPage] = useState(51);
      const [pagination, setPagination] =useState({
          start: 0,
          end: showPerPage,
      });
  
      const onPaginationChange = (start,  end) =>{
          setPagination({start: start, end: end});
      };
      const [searchTerm, setsearchTerm] = useState("");
      const [room, setRoom] = useState([])
      const navigate = useNavigate()
  
      const roomCollectionRef = collection(db, "Room");
      useEffect(() => {
          const getRoom = async () => {
              const data = await getDocs(roomCollectionRef);
              setRoom(data.docs.map((doc) => ({...doc.data(), id: doc.id })))
          }
          getRoom()
      }, [])
    return (
      
<>
<Navbar/>

<br/>
<br/>
<br/>
<br/>
<div className="aboutusback">

<div className="album py-1">

  <div className="container">


    <h1 style={{ color: "white" }} className='mb-2'><b>ROOM</b></h1>
    <nav aria-label="breadcrumb">
      <ol style={{ color: "white" }} className="breadcrumb">
        <li style={{ color: "white" }} className="breadcrumb-item"><Link style={{ color: "white" }} to="/" ><b>Home</b></Link></li>
        <li style={{ color: "white" }} className="breadcrumb-item active" aria-current="page">Room</li>
      </ol>
    </nav>

  </div>
</div>

</div>
<ScrollToTop smooth top="100" color="Darkblue" />

<br/>
<br/>
<center>
    <div className='textcolor'>
    <h2><b>HOTEL SANGAM</b></h2>
    </div>
    </center>
<h2 className="card-text mb-2" style={{paddingTop: "1rem", margin: "auto 0", color: "Gray", textAlign: "center"}}> 
<b> Our <span style={{color: "Darkblue", fontWeight: "bold", marginleft: '10px'}}>
    <Typewriter 
    loop
    cursor
    cursorStyle="_"
    typeSpeed={90}
    deleteSpeed={50}
    delaySpeed={1000}
    words={[ " Clean Room", "AC Room", "Safty Room"]}
    />
</span>
</b></h2>
<div className="album py-2">
        
            <div className="container">

                <div className="row">
               
                <div className="col-md-6">
                        
                        <div className="card-body">
                        <input type="text" className="form-control" placeholder="Search.." name="location" 
                        onChange ={(e) => {
                          setsearchTerm(e.target.value);
                        }}
                        />
                                <div className="d-flex justify-content-between align-items-center">
                                   
                                    
                            

                            </div>
                          
                    
                        </div>

                      
                    </div>
             
                    </div>
                  
                    </div>
                    </div>
<div className="album py-4">
        
        <div className="container">

            <div className="row">
            {room.filter((val)=>{
      if (setsearchTerm === ""){
        return val;
      } else if (
        val.title.toLowerCase().includes(searchTerm.toLowerCase()) 
      
      
       
  
      ){
        return val;
      }
    }).slice(pagination.start, pagination.end).map((bestwesitedevelopernadia) => { return (
       
            <div className="col-md-6" >
                        <div className="card mb-4 border-White box ">
                        <img  className="card-img-top" src={bestwesitedevelopernadia.img}   alt={bestwesitedevelopernadia.title}/>
                            <div className="card-body">
                                
                                <h6 className="card-text"> <b>{bestwesitedevelopernadia.title}</b></h6>
                                <p className="card-text">{bestwesitedevelopernadia.des}</p>
                                <button type="button" className="btn btn btn-dark me-1">
                                        {bestwesitedevelopernadia.work}  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
  <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z"/>
</svg>
                                      </button> < FacebookShareButton url="https://www..com/searchwebsite" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}>
    <FacebookIcon size={37} round={true}/>
</FacebookShareButton>

 <  WhatsappShareButton url="https://www..com/searchwebsite" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <WhatsappIcon size={37} round={true}/>
</ WhatsappShareButton>

<  TwitterShareButton url="https://www..com/searchwebsite" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <TwitterIcon size={37} round={true}/>
</ TwitterShareButton>

<  LinkedinShareButton url="https://www..com/searchwebsite" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <  LinkedinIcon size={37} round={true}/>
</ LinkedinShareButton>

<  EmailShareButton url="https://www..com/searchwebsite" quote={"India's Most Iconic Website Developer"} className='mb-3' hastag={"#WebApp"}
>
    <  EmailIcon size={37} round={true}/>
</ EmailShareButton>
                                <div className="d-flex justify-content-between align-items-center">
                              
                                <a href="tel: +91 9434237099"  className="buttoncall button1">    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-forward" viewBox="0 0 16 16">
  <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zm10.762.135a.5.5 0 0 1 .708 0l2.5 2.5a.5.5 0 0 1 0 .708l-2.5 2.5a.5.5 0 0 1-.708-.708L14.293 4H9.5a.5.5 0 0 1 0-1h4.793l-1.647-1.646a.5.5 0 0 1 0-.708z"/>
</svg> Call Now</a>
<ReactWhatsapp number="+91 9434237099" className="btn btn-success" message="I am interested Hotel Sangam"> WhatsApp Book</ReactWhatsapp>

                              
                            </div>
                            </div>
                            </div>
                               
                            </div>
                                )})} 
                            </div>
                            {/* <PaginationOld showPerPage={showPerPage} 
                onPaginationChange={onPaginationChange}
                total={developeweb.length}
                /> */}
                            </div>
                            </div>

</>
        
        );
    }
    
    export default Room;